import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import StoreFilterActions from './StoreFilterActions';
import StoreFilters from './StoreFilters';
import CustomCard from 'components/CustomCard';
import Table from 'components/Table';
import AnotherStore from './AnotherStore';
import { nullCompareOption } from 'pages/HomePage/data';
import LoadingOverlay from 'components/LoadingOverlay';
import { getHeight } from 'utils';

const styles = (theme) => ({
  dataHeader: {
    padding: '24px',
    width: '100%',
  },
  tableCard: {
    ...theme.content.card,
    width: '100%',
    margin: '16px 0',
  },
  helperText: {
    ...theme.typography.fontSizes.normalText,
    color: theme.palette.stroke,
    fontWeight: theme.typography.fontWeight.semiBold,
    margin: 0,
    padding: '8px 16px 4px 16px',
  },
  helperTextAstrix: {
    color: theme.palette.bgBadge,
    marginRight: 8,
  },
});

const useStyles = makeStyles(styles);

const StoreDetailsFilters = ({
  metricsOptions,
  seasonOptions,
  compareOptions,
  storeDetails,
  storeDepartmentDetails,
  setCompareAgaintFilter,
  isLoading,
  isLoadingTableData,
  headCells,
  isoptimizer,
  compareAgainstFilter,
  deltaChangeValue,
  deltaChangeOptions,
  handleDeltaChange,
  exportEndpoint,
}) => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const seasonParams = isoptimizer
    ? searchParams.get('optimized_season')
    : searchParams.get('season');
  const lySeason = searchParams.get('ly_season');

  const [tableHeadCells, setTableHeadCells] = useState(headCells);
  const [metrics, setMetrics] = useState([]);
  const [anotherStorePopup, setAnotherStorePopup] = useState(false);
  const [compareAgainst, setCompareAgaint] = useState({
    value: null,
    name: 'None',
    label: 'None',
  });
  const [season, setSeason] = useState(null);

  const onMetricsSelect = (_, values) => {
    filterHeadCells(values);
    setMetrics(values);
  };

  const onCompareSelect = (_, value) => {
    if (value?.value === 'another_store') return setCompareAgaint(value);
    filterHeadCellsForCompare(value);
    if (value?.value) {
      setCompareAgaintFilter({ name: value.value });
    } else {
      setCompareAgaintFilter({});
    }

    setCompareAgaint(value);
  };

  const onSeasonSelect = (_, value) => {
    if (value?.label) {
      let searchParams;

      if (isoptimizer) {
        searchParams = new URLSearchParams({
          optimized_season: value.label,
          ly_season: lySeason,
        });
      } else {
        searchParams = new URLSearchParams({ season: value.label });
      }
      setSearchParams(searchParams);
    }
  };

  const showDelta = (metrics) => {
    const deltaMetric = metrics?.some(
      (option) => option.column_name === 'delta_change'
    );

    const showDeltaCol = deltaMetric;
    return showDeltaCol;
  };

  const getLabel = (metric, season) => {
    if (metric?.value === 'lly') {
      return {
        0: `${isoptimizer && 'Optimized'} ${season?.year}-${season?.name}`,
        1: `${`${season?.year - 1}-${season?.name}`}`,
        2: `${`${season?.year - 2}-${season?.name}`}`,
        3: 'Delta',
      };
    } else if (metric?.value === 'prototype_store') {
      return {
        0: `Store Id - ${storeDetails?.store_number}`,
        1: `LY - Store Id - ${storeDetails?.store_number}`,
        2: `Prototype Store - ${storeDetails?.prototype_store_number}`,
        3: 'Delta',
      };
    } else {
      return {
        0: `${isoptimizer && 'Optimized'} Store Id - ${
          storeDetails?.store_number
        }`,
        1: `LY - Store Id - ${storeDetails?.store_number}`,
        2: `${
          compareAgainstFilter?.store_number || metric?.store_number
            ? `Store Id - ${
                metric?.store_number || compareAgainstFilter?.store_number
              }`
            : metric?.name
        }`,
        3: 'Delta',
      };
    }
  };

  const filterHeadCellsForCompare = (compareAgainstMetric, currSeason) => {
    const toggleHeads = [
      'optimized_area',
      'optimized_margin',
      'optimized_margin_persqft',
      'optimized_revenue',
      'optimized_revenue_persqft',
    ];
    const showDeltaColumn = showDelta(metrics);

    if (compareAgainstMetric?.value) {
      const headCells = tableHeadCells?.map((cell) => {
        if (cell.id.includes('sister_store')) {
          if (compareAgainstMetric?.value === 'sister_store') {
            cell.isVisible = true;
          } else {
            cell.isVisible = false;
          }
        }

        if (toggleHeads.indexOf(cell.column_name) > -1 && cell.group) {
          cell.colSpan = cell?.subColumns?.length;
          const labels = getLabel(compareAgainstMetric, season || currSeason);
          cell.subColumns = cell.subColumns?.map((subColumn, index) => {
            subColumn.isVisible =
              index === 3 ? (showDeltaColumn ? true : false) : true;
            if (index === 3) {
              subColumn.subLabel =
                deltaChangeValue?.value === 'ly' ? 'A - B' : 'A - C';
            }
            subColumn.label = labels[index];

            if (!subColumn.isVisible) {
              cell.colSpan--;
            }

            return subColumn;
          });
        }
        return cell;
      });

      setTableHeadCells(headCells);
    } else {
      const headCells = tableHeadCells?.map((cell) => {
        if (cell.id.includes('sister_store')) {
          cell.isVisible = false;
        }

        if (toggleHeads?.indexOf(cell.column_name) > -1 && cell.group) {
          const labels = getLabel(compareAgainstMetric, season || currSeason);
          cell.colSpan = cell?.subColumns?.length;
          cell.subColumns = cell.subColumns?.map((subColumn, index) => {
            subColumn.label = labels[index];

            if (index === 2) {
              subColumn.isVisible = false;
              cell.colSpan--;
            }

            if (index === 3) {
              subColumn.subLabel = 'A - B';
            }

            return subColumn;
          });
        }

        if (isoptimizer) {
          let currWidth = Number(cell?.colWidth?.split('%')[0]);
          currWidth += 6;
          if (!isNaN(currWidth)) {
            cell.colWidth = `${currWidth}%`;
          }
        }
        return cell;
      });

      setTableHeadCells(headCells);
    }
  };

  const filterHeadCells = (metrics) => {
    const cells = headCells;

    metricsOptions?.forEach((metric) => {
      const showMetric = metrics?.find(
        (metricOption) =>
          metricOption.column_name === metric.column_name &&
          metricOption.column_name !== 'delta_change'
      );

      cells?.forEach((cell) => {
        const labels = getLabel(compareAgainst, season);
        if (cell?.group) {
          cell.subColumns = cell?.subColumns?.map((subColumn, index) => {
            subColumn.label = labels[index];

            if (index === 2 && !compareAgainst?.value) {
              subColumn.isVisible = false;
            }

            if (index === 3) {
              subColumn.subLabel =
                deltaChangeValue?.value === 'ly' ? 'A - B' : 'A - C';
            }
            return subColumn;
          });
        }

        if (cell.column_name === metric.column_name && showMetric) {
          cell.isVisible = true;
        } else if (cell.column_name === metric.column_name && !showMetric) {
          cell.isVisible = false;
        }
      });
    });

    const showDeltaCol = showDelta(metrics);
    cells?.forEach((cell) => {
      let colSpan = 1;
      if (cell?.group) {
        colSpan = cell?.subColumns?.length;
        cell.subColumns[3].isVisible = showDeltaCol;
        cell?.subColumns?.map((subCol) => {
          if (!subCol.isVisible) {
            colSpan--;
          }
        });
      }
      cell.colSpan = colSpan;
    });

    setTableHeadCells(cells);
  };

  useEffect(() => {
    if (compareAgainst?.value === 'another_store') {
      setAnotherStorePopup(true);
    }
  }, [compareAgainst]);
  
  useEffect(() => {
    if (season?.year) {
      filterHeadCellsForCompare(compareAgainst || compareAgainstFilter, season);
    }
  }, [season]);

  useEffect(() => {
    if (season?.year) {
      filterHeadCellsForCompare(compareAgainst || compareAgainstFilter, season);
    }
  }, [season]);

  useEffect(() => {
    if (metricsOptions?.length) {
      const values = metricsOptions?.filter((metric) => metric.selected);
      setMetrics(values);
      filterHeadCells(values);
    }
  }, [metricsOptions?.length]);

  useEffect(() => {
    if (seasonOptions?.length && seasonParams) {
      const season = seasonOptions?.find(
        (season) => season.label === seasonParams
      );
      setSeason(season);
    }
  }, [seasonOptions, seasonParams]);

  useEffect(() => {
    filterHeadCellsForCompare(compareAgainst, season);
  }, [deltaChangeValue]);

  return (
    <>
      <CustomCard cardStyles={classes.tableCard}>
        <div className={classes.dataHeader}>
          <Grid
            container
            spacing={3}
            columns={12}
            justifyContent={'space-between'}
          >
            <Grid item xs={12} sm={6} md={7.2} xl={5.5}>
              <StoreFilters
                isoptimizer={isoptimizer}
                metrics={metrics}
                compareAgainst={compareAgainst}
                season={season}
                deltaChangeValue={deltaChangeValue}
                metricsOptions={metricsOptions}
                deltaOptions={deltaChangeOptions}
                compareOptions={[nullCompareOption, ...compareOptions]}
                seasonOptions={seasonOptions}
                onMetricsSelect={onMetricsSelect}
                onCompareSelect={onCompareSelect}
                handleDeltaChange={handleDeltaChange}
                onSeasonSelect={onSeasonSelect}
                isLoading={isLoading}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4.8}
              xl={6.5}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <StoreFilterActions
                season={season}
                isoptimizer={isoptimizer}
                seasonOptions={seasonOptions}
                storeDetails={storeDetails}
                compareAgainstFilter={compareAgainstFilter}
                exportEndpoint={exportEndpoint}
              />
            </Grid>
          </Grid>
        </div>
        <div>
          <LoadingOverlay
            loader={isLoadingTableData || !season?.year}
            text='Fetching Data'
            position='absolute'
            minHeight={460}
            maxHeight={`calc(100vh - ${getHeight('table-container')})`}
          />
          <Table
            headCells={tableHeadCells}
            data={storeDepartmentDetails}
            isLoading={isLoadingTableData}
            isGroupedData={true}
            tableHeight={460}
            noPagination
            id='store-table'
          />
        </div>
        {anotherStorePopup && (
          <AnotherStore
            storeId={storeDetails?.store_number}
            season={season}
            filterHeadCellsForCompare={(name) => {
              filterHeadCellsForCompare({
                ...compareAgainst,
                name: `Store Id - ${name}`,
                store_number: typeof name === 'number' && name,
              });
            }}
            seasonOptions={seasonOptions}
            handleOpen={() => setAnotherStorePopup(true)}
            handleClose={(val) => {
              if (val) {
                filterHeadCellsForCompare(val);
                setCompareAgaint(val);
              }
              setAnotherStorePopup(false);
            }}
            setCompareAgaintFilter={setCompareAgaintFilter}
          />
        )}
      </CustomCard>
      <p className={classes.helperText}>
        <span className={classes.helperTextAstrix}>*</span>
        Departments with fixed area
      </p>
      <p className={classes.helperText}>
        <span className={classes.helperTextAstrix}>**</span>
        Column values are aggregated differently based on data characteristics
      </p>
    </>
  );
};

export default StoreDetailsFilters;
